import React, { useEffect, useState } from 'react'
import Card from './Component/Card'
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { baseUrl } from './Constant';
import { jwtDecode } from "jwt-decode";
import { Navigate } from 'react-router-dom';

export default function Home() {

    let token = localStorage.getItem('token')


    const [totalRegister, setTotalRegister] = useState('0')
    const [subscriptionCount, setSubscriptionCount] = useState('0')
    const [subscriptionValue, setSubscriptionValue] = useState('0')
    const [wallet, setWallet] = useState('0');
    const [withdraw, setwithdraw] = useState('0');
    const [inactive, setInactive] = useState('0')
    const [avialableSpin, setAvialableSpin] = useState('0')
    const [winAmountData, setWinAmountData] = useState('0')
    const [expiredSpin, setExpiredSpin] = useState('0')

    const [loading, setLoading] = useState({
        totalRegister: true,
        subscriptionCount: true,
        subscriptionValue: true,
        wallet: true,
        withdraw: true,
        inactive: true,
        availableSpin: true,
        winAmountData: true,
    });

    useEffect(() => {
        const fetchData = async () => {


            try {
                const fetchTotalRegister = axios.get(`${baseUrl}/user/get-user-register`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }).then(response => {
                    const total_register = response.data.data.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    setTotalRegister(total_register);
                    setLoading(prev => ({ ...prev, totalRegister: false }));
                });

                const fetchSubscriptionCount = axios.get(`${baseUrl}/user/subscription_count`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }).then(response => {
                    const total_subscription_count = response.data.data.user_subscriptions_total_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    setSubscriptionCount(total_subscription_count);
                    setLoading(prev => ({ ...prev, subscriptionCount: false }));
                });

                const fetchSubscriptionValue = axios.get(`${baseUrl}/user/subscriptionValue`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }).then(response => {
                    // console.log(response.data,"fetchSubscriptionValue")
                    const totalamount = response.data.totalSubscriptionAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    setSubscriptionValue(totalamount);
                    setLoading(prev => ({ ...prev, subscriptionValue: false }));
                });

                const fetchWallet = axios.get(`${baseUrl}/user/prizes-wallet`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }).then(response => {
                    // console.log(response.data,"fetchWallet")
                    const total_wallet_amount = response.data.total_wallet_reward.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    setWallet(total_wallet_amount);
                    setLoading(prev => ({ ...prev, wallet: false }));
                });

                const fetchWithdraw = axios.get(`${baseUrl}/user/withdrawals-status`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }).then(response => {
                    const withDrawn = response.data.total_withdraw_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    setwithdraw(withDrawn);
                    setLoading(prev => ({ ...prev, withdraw: false }));
                });

                const fetchInactive = axios.get(`${baseUrl}/user/inactive_subscribers`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }).then(response => {
                    console.log("responseData", response.data)
                    setInactive(response.data.data.count);
                    setLoading(prev => ({ ...prev, inactive: false }));
                });

                const fetchAvailableSpin = axios.get(`${baseUrl}/user/available-spins`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }).then(response => {
                    const availableSpin = response.data.data.spinsData.available_spins.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    setAvialableSpin(availableSpin);
                    setLoading(prev => ({ ...prev, availableSpin: false }));
                });

                const fetchPrizeWon = axios.get(`${baseUrl}/user/prizesWon`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }).then(response => {
                    console.log(response.data, "responseprizewon")
                    const win_amount_Data = response.data.totalWinAmount?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    setWinAmountData(win_amount_Data);
                    setLoading(prev => ({ ...prev, winAmountData: false }));
                });
                const fetchExpiredSpins = axios.get(`${baseUrl}/user/get-expired-spins`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                }).then(response => {
                    // console.log(response.data, "responseprizewon")
                    const win_amount_Data = response.data.rows;
                    setExpiredSpin(win_amount_Data);
                });


                await Promise.all([
                    fetchTotalRegister,
                    fetchSubscriptionCount,
                    fetchSubscriptionValue,
                    fetchWallet,
                    fetchWithdraw,
                    fetchInactive,
                    fetchAvailableSpin,
                    fetchPrizeWon,
                    fetchExpiredSpins,
                ]);

            } catch (error) {
                console.error('Error fetching data:', error);

            }
        };

        fetchData();
    }, []);


    if (!token) {
        return <Navigate to='/login' />
    }

    const decoded = jwtDecode(token);
    const { username } = decoded;


    return (
        <div className="homePage">
            <div className="cardsection">
                <div className="row">
                    {(username === "backend") ? (<div className="col-lg-3 col-md-4 ">
                        <NavLink to='/registered-subscriber' className='textDecoration'>
                            <Card
                                title={"Total Registered Subscribers"}
                                number={loading.totalRegister ? 'Loading...' : totalRegister}
                                className='card_1 myCard'

                            />
                        </NavLink>
                    </div>) : ""}

                    {(username === "backend") ? (<div className="col-md-4 col-lg-3">
                        <NavLink to='/inactive-subscriber' className='textDecoration'>
                            <Card
                                title={"Inactive Subscribers"}
                                number={loading.inactive ? 'Loading...' : inactive}
                                className='card_2 myCard'

                            />
                        </NavLink>
                    </div>) : ""}

                    {(username === "backend") ? (<div className="col-lg-3 col-md-4">
                        <NavLink to='/count-subscription-renewal-in-month' className='textDecoration'>
                            <Card
                                title={"Count: Subscription and Renewal in Month"}
                                number={loading.subscriptionCount ? 'Loading...' : subscriptionCount}
                                className='card_3 myCard'

                            />
                        </NavLink>
                    </div>) : ""}

                    <div className={`${username === 'backend' ? 'col-lg-3 col-md-4' : 'col-lg-4 col-md-4'}`}>
                        <NavLink to='/value-subscription-renewal-in-month' className='textDecoration'>
                            <Card
                                title={"Value: Subscription and Renewal in Month"}
                                number={loading.subscriptionValue ? 'Loading...' : `N ${subscriptionValue}`}
                                className='card_4 myCard'

                            />
                        </NavLink>
                    </div>
                    <div className={`${username === 'backend' ? 'col-lg-3 col-md-4' : 'col-lg-4 col-md-4'}`} >
                        <NavLink to='/prize-won' className='textDecoration'>
                            <Card
                                title={"Prize Won"}
                                number={loading.winAmountData ? 'Loading...' : `N ${winAmountData}`}
                                className='card_5 myCard'

                            />
                        </NavLink>
                    </div>
                    {(username === "backend") ? (<div className="col-lg-3 col-md-4">
                        <NavLink to='/prizes-in-wallet' className='textDecoration'>
                            <Card
                                title={"Value of Prizes in Wallet"}
                                number={loading.wallet ? 'Loading...' : `N ${wallet}`}
                                className='card_6 myCard'

                            />
                        </NavLink>
                    </div>) : ""}


                    <div className={`${username === 'backend' ? 'col-lg-3 col-md-4' : 'col-lg-4 col-md-4'}`}>
                        <NavLink to='/withdrawals-status' className='textDecoration'>
                            <Card
                                title={"Withdrawals Status"}
                                number={loading.withdraw ? 'Loading...' : `N ${withdraw}`}
                                className='card_7 myCard'

                            />
                        </NavLink>
                    </div>
                    {(username === "backend") ? (<div className="col-lg-3 col-md-4">
                        <NavLink to='/available-spins' className='textDecoration'>
                            <Card
                                title={"Available Spins"}
                                number={loading.availableSpin ? 'Loading...' : avialableSpin}

                                className='card_8 myCard'

                            />
                        </NavLink>
                    </div>) : ""}
                    {(username === "backend") ? (<div className="col-lg-3 col-md-4">
                        <NavLink to='/get-expired-spins' className='textDecoration'>
                            <Card
                                title={"Expired Spins"}
                                number={expiredSpin ? expiredSpin : '0'}
                                className='card_8 myCard'

                            />
                        </NavLink>
                    </div>) : ""}

                </div>
            </div>

        </div>
    )
}
