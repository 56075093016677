import Card from './Component/Card';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios';
import { baseUrl } from './Constant';
import moment from 'moment';
import Notiflix from 'notiflix';
export default function Count() {
    let token = localStorage.getItem('token');

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [data, setData] = useState('')
    const [network, setNetwork] = useState('')
    const [totalCount, setTotalCount] = useState('0')

    const [pagination, setPagination] = useState({ page: 1, pageSize: 100 });
    const [tcount, setTcount] = useState('')
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    let startFormattedDate = '';
    let endFormattedDate = '';
    let startYear = startDateObj.getFullYear();
    let startMonth = ('0' + (startDateObj.getMonth() + 1)).slice(-2);
    let startDates = ('0' + startDateObj.getDate()).slice(-2);

    let endYear = endDateObj.getFullYear();
    let endMonth = ('0' + (endDateObj.getMonth() + 1)).slice(-2);
    let endDates = ('0' + endDateObj.getDate()).slice(-2);

    if (startDate && endDate) {
        startFormattedDate = `${startYear}-${startMonth}-${startDates}`;
        endFormattedDate = `${endYear}-${endMonth}-${endDates}`;
    }

    console.log(startFormattedDate, "startDate")
    console.log(endFormattedDate, "startperiod")

    // this date for period handling
    const d = new Date();
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const end_date = `${year}-${month}-${day}`;



    const handleNetworkChange = (event) => {
        setNetwork(event.target.value);
    }

    useEffect(() => {
        const fetchData = async () => {
            Notiflix.Loading.pulse()
            try {
                let apiUrl = `${baseUrl}/user/subscription_count?`;

                if (startDate && endDate) {
                    apiUrl += `start_date=${startFormattedDate}&end_date=${endFormattedDate}`;

                }

                if (network !== 'all') {
                    apiUrl += `&network=${network}`;
                }
                apiUrl += `&page=${pagination.page}&limit=${pagination.pageSize}`;
                const response = await axios.get(
                    apiUrl,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                console.log(response.data.data, "fhdjhfjkhdjk")

                const modifiedData = response.data.data.subscription_type.map((item, i) => {
                    let subscriptionMessage = '';
                    switch (item.subscription_type) {
                        case '1_spin_auto_daily_pack':
                            subscriptionMessage = '1 Spin/Day @ N50';
                            break;
                        case '2_spin_auto_daily_pack':
                            subscriptionMessage = '2 Spin/Day @ N100';
                            break;
                        case '5_spin_auto_weekly_pack':
                            subscriptionMessage = '5 Spin/7 Days @ N250';
                            break;
                        case '1_spin_one_off_pack':
                            subscriptionMessage = '1 Spin/One-off @ N50';
                            break;
                        case '2_spin_one_off_pack':
                            subscriptionMessage = '2 Spin/One-off @ N100';
                            break;
                        case '5_spin_one_off_pack':
                            subscriptionMessage = '5 Spin/One-off @ N250';
                            break;
                        case '10_spin_one_off_pack':
                            subscriptionMessage = '10 Spin/One-off @ N500';
                            break;
                        default:
                            subscriptionMessage = item.subscription_type;
                    }

                    return {
                        ...item,
                        subscription_types: item.subscription_pack.pack_name,
                        period: `${startFormattedDate ? moment(startFormattedDate).format('DD/MM/YYYY') : moment(item.start_date.split('T')[0]).format('DD/MM/YYYY')} to ${endFormattedDate ? moment(endFormattedDate).format('DD/MM/YYYY') : moment(end_date).format('DD/MM/YYYY')}`,
                        id: ((pagination.page - 1) * pagination.pageSize) + 1 + i,
                    }
                });
                const total_sunscription_count = response.data.data.user_subscriptions_total_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                setTotalCount(total_sunscription_count)
                setData(modifiedData);
                setTcount(response.data.data.rows)
                Notiflix.Loading.remove();
            } catch (error) {
                console.log(error, "api does not work");
                Notiflix.Loading.remove();
                Notiflix.Notify.failure(error.message);
            }
        };

        // fetchData();

        const interval = setTimeout(fetchData,10)
        return ()=>clearTimeout(interval);
    }, [startDate, endDate, network, pagination]);

    // useEffect(() => {
    //     if (startDate && endDate) { 

    //     }
    // },[startDate,endDate,startperiod,endPeriod])

    const handleDownload = async () => {
        try {
            let apiUrl = `${baseUrl}/user/export-file-data/subscription-count?`;

            if (startDate && endDate) {
                apiUrl += `start_date=${startFormattedDate}&end_date=${endFormattedDate}`;
            }

            if (network !== 'all') {
                apiUrl += `&network=${network}`;
            }



            const response = await axios.get(apiUrl, { responseType: 'blob' });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Count: Subscription and Renewal ${moment().format('DD-MM-YYYY')} .csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.log(error, "API does not work");
        }
    }

    function CustomToolbar() {
        return (
            <button onClick={handleDownload} className='btn btn-dark'>Export</button>
        );
    }

    const columns = [
        {
            field: 'id',
            headerName: 'SN',
            width: 20,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'period',
            headerName: 'Period',
            flex: 1,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'network',
            headerName: 'Network',
            flex: 1,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'subscription_types',
            headerName: 'Type',
            flex: 1,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'


        },
        {
            field: 'count',
            headerName: 'Count',
            width: 120,
            headerClassName: 'vertical-line',
            cellClassName: 'vertical-line',
            align: 'center',
            headerAlign: 'center'
        },
    ];
    return (
        <div className='myContainer'>
            <div className='innerContainer'>
                <div className='containerCard'>
                    <div className="col-md-3">
                        <Card
                            title={"Count: Subscription and Renewal"}
                            number={totalCount ? totalCount : '0'}
                            className='myCard indiviusalCard'
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='filter'>
                        <div className='filterByDate'>
                            <div className='title'>
                                <h6>
                                    Filter By Date:
                                </h6>
                            </div>
                            <div className='datePicker'>
                                <div>
                                    <p className='m-0'>Start Date</p>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        dateFormat='dd/MM/yyyy'
                                        maxDate={new Date()}
                                    />
                                </div>
                                <div>
                                    <p className='m-0'>End Date</p>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        dateFormat='dd/MM/yyyy'
                                        maxDate={new Date()}
                                        minDate={startDate}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='filterByNetwork py-3 d-flex justify-content-between flex-wrap'>
                            <div>
                                <div className='title'>
                                    <h6>Filter By Network:</h6>
                                </div>
                                <div className='networkPicker'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="network" id="allNetwork" value="all" onChange={handleNetworkChange} checked={network === 'all'} />
                                        <label className="form-check-label" htmlFor="allNetwork">All</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="network" id="mtnNetwork" value="mtn" onChange={handleNetworkChange} checked={network === 'mtn'} />
                                        <label className="form-check-label" htmlFor="mtnNetwork">MTN</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="network" id="airtelNetwork" value="airtel" onChange={handleNetworkChange} checked={network === 'airtel'} />
                                        <label className="form-check-label" htmlFor="airtelNetwork">Airtel</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="network" id="gloNetwork" value="glo" onChange={handleNetworkChange} checked={network === 'glo'} />
                                        <label className="form-check-label" htmlFor="gloNetwork">Glo</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="network" id="etisalatNetwork" value="9mobile" onChange={handleNetworkChange} checked={network === '9mobile'} />
                                        <label className="form-check-label" htmlFor="etisalatNetwork">9mobile</label>
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <div className='title'>
                                    <h6>Filter By Page No:</h6>
                                </div>
                                <div>
                                    <div>
                                        <input type="text" className="form-control" placeholder="Page Number"
                                            onChange={(e) => {
                                                const page = e.target.value ? parseInt(e.target.value, 10) : 1;
                                                setPagination((prev) => ({ ...prev, page }));
                                            }}
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>

                <div className="datagrid containerDataGrid">
                    <h5>Count: Subscription and Renewal</h5>

                    <div className="datagridBox">
                        <Box sx={{ height: '500px', width: '100%' }}>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                paginationModel={{
                                    page: pagination.page - 1,
                                    pageSize: pagination.pageSize,
                                }}

                                paginationMode='server'
                                pageSize={pagination.pageSize}
                                rowCount={tcount}
                                pageSizeOptions={[10]}
                                rowsPerPageOptions={[10]}
                                onPaginationModelChange={(e, f) => { setPagination({ ...pagination, page: e.page + 1, pageSize: e.pageSize }) }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}

                            />
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
}