import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import { baseUrl } from './Constant';
import Notiflix from 'notiflix';

function SuspendUser() {
  Notiflix.Notify.init({
    position: 'center-top',
  });

  let token = localStorage.getItem('token')

  const [mobileNumber, setMobileNumber] = useState('');
  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setMobileNumber(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/user/suspend`, {
        msisdn: mobileNumber,
      },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }

      );
      console.log(response, "suspend user")
      setMobileNumber('');
      Notiflix.Notify.success(response.data.message);
    } catch (error) {
      Notiflix.Notify.failure(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <div>
      <div className="addPrize">
        <div className="addPrize_folder">
          <h4 className="mb-3">Unsubscribed User</h4>
          <Form onSubmit={handleSubmit}>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Mobile number"
                value={mobileNumber}
                onChange={handleInputChange}
                type="text"
              />
              <Button variant="primary" id="button-addon2" type="submit">
              Un-subscribed 
              </Button>
            </InputGroup>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default SuspendUser;
