import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const DataGridComponent = ({
    rows,
    columns,
    pageSize,
    page,
    rowCount,
    onPaginationModelChange,
    toolbar: CustomToolbar,
}) => {
    return (
        <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: pageSize,
                        page: page - 1,
                    },
                },
            }}
            pagination
            paginationMode="server"
            // pageSize={100}
            // page={page - 1}
            rowCount={rowCount}
            pageSizeOptions={[10]}
            // rowsPerPageOptions={[50, 100]}
            onPaginationModelChange={onPaginationModelChange}
            slots={{
                toolbar: CustomToolbar,
            }}
        />
    );
};

export { DataGridComponent };
