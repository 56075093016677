import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Layout from './Layout';
import Login from './Login';
import Home from './Home';
import { LoginRoute } from './ProtectedRoute';
import AddPrize from './AddPrize';
import AddSchemeList from './AddSchemeList';
import RegisterdSubscriber from './RegisterdSubscriber';
import InactiveSubscriber from './InactiveSubscriber';
import Count from './Count';
import Value from './Value';
import PrizeWon from './PrizeWon';
import PrizeWallet from './PrizeWallet';
import Withdrawals from './Withdrawals';
import SpinsAvaible from './SpinsAvaible';
import UserAccount from './UserAccount';
import UsedSpin from './UsedSpin';
import AllUsers from './AllUsers';
import SendSMS from './SendSMS';
import SuspendUser from './SuspendUser';
import SmsCampaign from './SmsCampaign';
import ExpiredSpin from './ExpiredSpins';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='login' element={<LoginRoute><Login /></LoginRoute>} />
        <Route path="/" element={<Layout />}>
          <Route path="/backend" element={<Home />} />
          <Route path="/regulatory" element={<Home />} />
          <Route path='/add-prize' element={<AddPrize />} />
          <Route path='/add-scheme' element={<AddSchemeList />} />
          <Route path='/registered-subscriber' element={<RegisterdSubscriber />} />
          <Route path='/inactive-subscriber' element={< InactiveSubscriber />} />
          <Route path='/count-subscription-renewal-in-month' element={<Count />} />
          <Route path='/value-subscription-renewal-in-month' element={<Value />} />
          <Route path='/prize-won' element={<PrizeWon />} />
          <Route path='/prizes-in-wallet' element={<PrizeWallet />} />
          <Route path='/withdrawals-status' element={<Withdrawals />} />
          <Route path='/available-spins' element={<SpinsAvaible />} />
          <Route path='/user-accounts' element={<UserAccount />} />
          <Route path='/used-spin' element={<UsedSpin />} />
          <Route path='/get-expired-spins' element={<ExpiredSpin/>} />
          <Route path='/all-users' element={<AllUsers />} />
          <Route path='/send-sms' element={<SendSMS />} />
          <Route path='/suspend-user' element={<SuspendUser />} />
          <Route path='/sms-campaign' element={<SmsCampaign />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
